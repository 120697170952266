import "./src/styles/global.css"

import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { ApolloProvider } from "@apollo/client"
import createApolloClient from "./src/utils/apolloClient"
import { useAuth0 } from '@auth0/auth0-react'

import ProtectedPages from './src/components/ProtectedPages'
import { GlobalStateProvider } from './src/contexts/globalStates'

const ApolloWrapper = ({ element }) => {
  const { getAccessTokenSilently } = useAuth0()

  const client = createApolloClient(async () => {
    try {
      return await getAccessTokenSilently()
    } catch (err) {
      console.error('Error fetching token:', err)
      return null
    }
  })

  return <ApolloProvider client={client}>{element}</ApolloProvider>
}

export const wrapRootElement = ({ element }) => (
  <GlobalStateProvider>
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.GATSBY_AUTH0_CALLBACK_URL,
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: "openid profile email"
      }}
      cacheLocation="localstorage"
    >
      {typeof window === "undefined" ? null : (
        <ApolloWrapper element={element} />
      )}
      
    </Auth0Provider>
  </GlobalStateProvider>
)

export const wrapPageElement = ({ element, props }) => {
  const protectedPages = ['/account/verify-email/', '/profile/edit/', '/profile/', '/craft/']
  if (protectedPages.includes(props.location.pathname)) {
    return <ProtectedPages element={element} />
  }

  return element
}

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    const { pathname, href } = location
    
    if (!pathname.startsWith('/login') && !pathname.startsWith('/callback') && !href.includes('auth.ju-materials.com') && !href.includes('accounts.google.com')) {
      localStorage.setItem('lastVisitedPage', pathname)
    }
  }
}
