import React, { useState, useEffect, useRef, useCallback } from 'react'
const { useLocation } = require('@gatsbyjs/reach-router')
import { useQuery } from '@apollo/client'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import Header from './Header'
import Navbar from './Navbar'
import Loading from './Loading'
import Sidebar from './Sidebar'
import NotificationBar from './NotificationBar'
import { GET_CURRENT_USER } from '../graphql/queries'
import { useGlobalState } from '../contexts/globalStates'

interface LayoutProps {
  loading: boolean
  children: React.ReactNode
  message: string
}

type ChildProps = {
  refetch: () => void
}

export default function Layout({ loading, children, message }: LayoutProps) {
  const refs = {
    sidebar: useRef<HTMLDivElement>(null),
    hamburgerButton: useRef<HTMLImageElement>(null),
    touchStartX: useRef(0),
    touchEndX: useRef(0)
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const location = useLocation()
  const { setUser } = useGlobalState()
  const { data: queryData, error, loading: queryLoading, refetch } = useQuery(GET_CURRENT_USER, {
    onCompleted: () => setIsInitialLoad(false)
  })

  useEffect(() => {
    if (queryData) {
      setUser(queryData.getCurrentUser)
    }
    if (error) {
      console.log(error)
    }
  }, [queryData, error])

  const handleTouchStart = (e: TouchEvent) => {
    refs.touchStartX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e: TouchEvent) => {
    refs.touchEndX.current = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    if (refs.touchStartX.current - refs.touchEndX.current > 50) 
      setIsSidebarOpen(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (refs.sidebar.current && !refs.sidebar.current.contains(event.target as Node)) {
      setIsSidebarOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSidebarOpen, handleClickOutside])

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)
    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsHeaderVisible(false)
      } else {
        setIsHeaderVisible(true)
      }
      setLastScrollY(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  const disallowedPages = ['/craft/', '/profile/edit/', '/search/']

  const shieldLogo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "shield-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = getImage(shieldLogo.placeholderImage)

  if (isInitialLoad && queryLoading) return (
    <div className='h-dvh w-full flex items-center justify-center'>
      {image && <GatsbyImage image={image} alt="logo" className='w-8/12' />}
    </div>
  )

  return (
    <div>
      {!disallowedPages.includes(location.pathname) && (
        <header
          className={`w-full fixed z-10 transition-all duration-300 ${location.pathname !== '/' ? 'bg-transparent' : 'bg-background border-b border-b-borders shadow-lg'} ${isHeaderVisible ? 'top-0' : '-top-16'}`}
        >
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} buttonRef={refs.hamburgerButton} />
          {loading && <Loading />}
        </header>
      
      )}
      <main className={`transition-all duration-300 ${isSidebarOpen && 'blur-sm'} ${!disallowedPages.includes(location.pathname) && 'pb-12'}`}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<ChildProps>(child) && typeof child.type !== "string") {
          return React.cloneElement(child as React.ReactElement<ChildProps>, { refetch })
        }
        return child
      })}
      </main>
      {!disallowedPages.includes(location.pathname) && (
        <>
          <nav className="fixed bottom-0 h-12 w-full bg-background border-t border-t-borders px-8">
            <Navbar />
          </nav>
          <div
            id="sidebar"
            ref={refs.sidebar}
            className={`fixed top-0 left-0 w-10/12 h-screen bg-background border-r border-r-borders z-20 transition-transform duration-500 ease-in-out transform ${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
          >
            <Sidebar />
          </div>
        </>
      )}
      <NotificationBar message={message} />
    </div>
  )
}
