exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[username]-tsx": () => import("./../../../src/pages/[username].tsx" /* webpackChunkName: "component---src-pages-[username]-tsx" */),
  "component---src-pages-account-settings-tsx": () => import("./../../../src/pages/account/settings.tsx" /* webpackChunkName: "component---src-pages-account-settings-tsx" */),
  "component---src-pages-account-verify-email-tsx": () => import("./../../../src/pages/account/verify-email.tsx" /* webpackChunkName: "component---src-pages-account-verify-email-tsx" */),
  "component---src-pages-callback-tsx": () => import("./../../../src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-craft-tsx": () => import("./../../../src/pages/craft.tsx" /* webpackChunkName: "component---src-pages-craft-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

