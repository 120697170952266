import React from 'react'
import { Link } from 'gatsby'
const { useLocation } = require('@gatsbyjs/reach-router')

import { useGlobalState } from "../contexts/globalStates"

import { GrHomeRounded } from "react-icons/gr"
import { AiOutlinePlus } from "react-icons/ai"
import { PiChatCircleDots } from "react-icons/pi"
import { IoNotificationsOutline } from "react-icons/io5"

export default function Navbar() {
  const location = useLocation()
  const { state } = useGlobalState()

  const navItems = [
    { link: '/', icon: <GrHomeRounded className={`text-2xl transition-all duration-300 text-dimmed ${location.pathname === '/' && 'fill-dimmed'}`}/> },
    { link: '/craft/', icon: <AiOutlinePlus className='text-dimmed text-4xl'/> },
    { link: '/chat/', icon: <PiChatCircleDots className='text-dimmed text-3xl'/> },
    { link: '/notifications/', icon: <IoNotificationsOutline className='text-dimmed text-3xl'/> },
    { link: `/${state.user?.username}/` || '/profile/', icon: <div className={`w-7 h-7 rounded-full overflow-hidden ${location.pathname === `/${state.user?.username}/` && 'border-2 border-dimmed'}`}><img src={state.user?.profilePicture || '/images/user-circle.svg'} alt='profile image' className='w-full h-full bg-white' /></div>  }
  ]
  
  return (
    <ul className='flex items-center h-full justify-between gap-3'>
      {navItems.map(({link, icon}, index) => (
        <li key={index}>
          <Link to={link}>
            {icon}
          </Link>
        </li>
      ))}
    </ul>
  )
}
