import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
const { useLocation } = require('@gatsbyjs/reach-router')
import { RxHamburgerMenu } from "react-icons/rx"
import { CiSearch } from "react-icons/ci"

const Header = ({ setIsSidebarOpen, buttonRef }: any) => {
  const location = useLocation()

  const textLogo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "text-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 104
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const image = getImage(textLogo.placeholderImage)

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevState: any) => !prevState);
  }

  return (
    <div className='flex items-center justify-between w-full h-12 px-2'>
      <div className='flex items-center gap-4'>
        <button ref={buttonRef} onClick={handleSidebarToggle}>
          <RxHamburgerMenu className='text-light text-2xl' />
        </button>
        {location.pathname === '/' && (
          <Link to='/'>
            {image && <GatsbyImage image={image} alt="logo" />}
          </Link>
        )}
      </div>
      <Link to='/search'>
        <CiSearch className='text-light text-2xl' />
      </Link>
    </div>
  );
};

export default Header;
